module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Edubao","short_name":"Edubao","icons":[{"src":"images/favicon.ico","sizes":"64x64 32x32 24x24 16x16","type":"image/x-icon"},{"src":"images/logo192.png","sizes":"192x192","type":"image/png"},{"src":"images/logo512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","display":"standalone","theme_color":"#000000","background_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":100,"siteSpeedSampleRate":10,"cookieDomain":"https://edubao.asia","exclude":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
